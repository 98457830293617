.table-image {
  width: 75px;
  /*height: 75px;*/
  /* border: 2px solid white; */
}
.excel-color {
  background-color: #008000 !important;
  color: #fff !important;
}
.list-style {
  width: 70px !important;
}
.file-info {
  font-size: 11px;
  color: #a7a7a7;
  margin-top: 3px;
}
.update-banner {
  width: 120px;
}
.update-image {
  width: 150px;
  height: auto;
}
.react-daterange-picker__wrapper {
  border: 0 !important;
}

.tab-title a {
  color: rgb(0, 0, 0) !important;
}
a.nav-color {
  color: rgb(0, 0, 0) !important;
}
.label-list {
  text-align: end;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}
.cutome-th {
  border-bottom: 1px solid #c8ced3;
  width: 12%;
}

.required {
  color: red;
}
.student-list {
  width: 200px !important;
}
.image-circle {
  /* border-radius: 20%; */
  width: 75px;
  height: 75px;
}
/* .image-border {
  border: 2px solid white;
} */
.list-width {
  width: 70px !important;
}
/* .course-list {
  width: 100% !important;
} */
.custome-table-th-td th {
  width: 25% !important;
}
.custome-table-th-td td {
  width: 75% !important;
}
.switch-label .switch-slider::before {
  z-index: 0 !important;
}
.react-read-more-read-less {
  color: #20a8d8;
}
.react-read-more-read-less-more {
  color: #20a8d8;
}
.select-invalid {
  border: 1px solid #f86c6b !important;
  border-radius: 5px !important;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-position: right calc(2px) center !important;
}

.custome-date-Range > .react-datepicker-wrapper {
  width: 50% !important;
}
